@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1,
h2,
h3 {
  color: #333;
}

input,
button {
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
}

button {
  background-color: #10B981;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.info-icon {
  cursor: pointer;
  color: #007bff;
  margin-left: 5px;
}

.info-icon:hover::after {
  content: attr(title);
  position: absolute;
  background: #f9f9f9;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 1;
  white-space: nowrap;
}

.inspection-checklist .checklist-section {
  margin-bottom: 20px;
}

.inspection-checklist .checklist-item {
  margin-bottom: 10px;
}

.comparable-market-analysis table {
  font-size: 14px;
}

pre {
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.buying-progress {
  margin: 20px 0;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.5s ease-in-out;
}

.inspection-checklist .checklist-item input[type="checkbox"] {
  margin-right: 10px; /* Adjust this value to control the space between the checkbox and label */
}
/* src/App.css */
/* ... (keep the existing styles) ... */

.feature-button .feature-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 8px;
  fill: none;
  stroke: white;
  stroke-width: 1.5;
}

.feature-button span {
  font-size: 0.8em;
  text-align: center;
}

/* ... (keep the rest of the existing styles) ... */

.recent-analyses .house-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  fill: none;
  stroke: #1a73e8;
  stroke-width: 1.5;
}

@keyframes flow {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-10px) scale(1.05);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

.house-skeleton {
  animation: flow 10s ease-in-out infinite;
}

@layer base {
  :root {
    --radius: 0.5rem;
  }
}